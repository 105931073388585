.rst__tree {
  & .rst__rowContents {
    padding: 0 20px;
    min-width: 340px;
    border-radius: 6px;
  }

  & .rst__row {
    box-shadow: 4px 4px 5px rgba(182, 182, 182, 0.2);
  }

  & .rst__rowToolbar {
    display: flex;
    column-gap: 12px;
  }

  & .rst__rowTitle {
    color: $dark40;
  }

  & .rst__expandButton, 
  & .rst__collapseButton {
    width: 26px;
    height: 26px;
    box-shadow: 0 0 0 1px $light60;

    &:hover:not(:active) {
      width: 26px;
      height: 26px;
    }
  }

  & .rst__lineBlock {
    &::before,
    &::after {
      background-color: $light60;
    }
  }

  & .rst__lineChildren {
    &::after {
      background-color: $light60;
    }
  }

  // select 
  &.select-sorting {
    position: relative;
    margin: 0 0 0 -44px;
    padding: 20px;
    width: 100%;
    right: -44px;

    & .rst__node {

      & > .rst__lineBlock,
      & > .rst__lineHalfHorizontalRight {
        width: 0 !important;
        height: 0 !important;
      }

      &:not(:first-child) {
      }
    }

    & .rst__node {

      & .form-group {
        position: relative;
        display: flex;
        align-items: center;

        & label {
          padding: 0 30px 0 10px;
        }
      }

      &:not(:first-child) {
        & .form-group {
          & label {
            user-select: none;
          }
        }
      }
    }

    & .rst__lineBlock {
      display: none;
    }

    & .rst__rowWrapper {
      padding: 0;
    }

    & .rst__rowContentsDragDisabled {
      border: 0 !important;
      box-shadow: none !important;
    }

    & .rst__rowLabel {
      background-color: red !important;
      padding: 0 !important;
      flex: inherit !important;
    }

    & .rst__row {
      box-shadow: none !important;
    }

    & .rst__rowContents {
      min-width: auto !important;
      border-radius: 0 !important;
      padding: 0 !important;
      display: flex !important;
      flex: inherit !important;
      padding-left: 0 !important;
    }

    & .rst__nodeContent {
      & div {
        & > button {
          display: none !important;
        }

        & > .rst__lineChildren {
          display: none;
        }
      }
    }
  }
}