.blog-row {
  display: flex;
  width: 100%;
}

.blog-row label {
  width: 150px;
  min-width: 150px;
}

.blog-row .ant-form-item {
  width: 100%;
}

.blog-row div div div div div input {
  border-radius: 10px;
}

.selected-blogimage-container {
  margin-bottom: 15px;
}

.selected-blogimage-container {
  display: flex;
  flex-direction: column;
  align-items: start; /* To align the label to the start */
}

.blogimage-container {
  position: relative; /* To position the DELETE text over the image */
  display: inline-block; /* To take only the space it needs */
}

.blogimage {
  max-width: 250px; /* Or whatever width you prefer */
  border-radius: 10px; /* Rounded corners */
  display: block; /* To remove any bottom margin */
  transition: filter 0.3s; /* Optional: For a smooth transition on hover */
}

.blogimage:hover {
  filter: brightness(0.7); /* Optional: Dims the image a bit on hover */
  background-color: rgb(0, 0, 0);
  cursor: pointer;
  filter: blur(3px); /* adjust the pixel value as needed */
}

.blogimage-deleteicon {
  position: absolute; /* Positioned over the image */
  top: 50%;
  left: 69%;
  transform: translate(-50%, -50%); /* Centered over the image */
  // background-color: rgba(255, 0, 0, 0.7); /* Red background with opacity */
  color: white; /* White text */
  padding: 5px 10px; /* Some spacing */
  border-radius: 5px; /* Rounded corners */
  font-weight: bold; /* Bold text */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s; /* Smooth transition */
  pointer-events: none; /* Ensures that the DELETE text doesn't interfere with click events on the image */
  background-color: #da010194;
  // filter: blur(3px); /* adjust the pixel value as needed */
}

.blogimage:hover + .blogimage-deleteicon {
  opacity: 1; /* Show the DELETE text when image is hovered */
  cursor: pointer;
}

.blogimage-deleteicon span {
  font-size: 32px;
  color: white;
  min-height: 50px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blogimage-container {
  display: flex;
}

.blogimage-container label {
  width: 150px;
  min-width: 150px;
}

.blog-row .tox-tinymce {
  width: 100%;
}
