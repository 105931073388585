.updateproduct-row {
  display: flex;
  width: 100%;
}

.updateproduct-row label {
  width: 150px;
  min-width: 150px;
}

.input-updateproductform {
  width: 300px;
  border-radius: 10px;
}

.select-updateproductform div span {
  width: 268px;
  border-radius: 10px;
}

.datepicker-updateproductform div span {
  width: 100px;
  min-width: 100px;
  border-radius: 10px;
}

.updateproduct-row .tox-tinymce {
  width: 500px !important;
}
