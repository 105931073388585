:root {
  --official-color: #6ad4a6;
}

// default colors
$white: hsl(0, 0%, 100%);
$black: hsl(0, 0%, 0%);

// dark
$dark90: hsl(0, 0%, 5%);
$dark80: hsl(0, 0%, 10%);
$dark70: hsl(0, 0%, 15%);
$dark60: hsl(0, 0%, 20%);
$dark50: hsl(0, 0%, 25%);
$dark40: hsl(0, 0%, 30%);
$dark30: hsl(0, 0%, 35%);
$dark20: hsl(0, 0%, 40%);
$dark10: hsl(0, 0%, 45%);
$dark5: hsl(0, 0%, 50%);

// light
$light90: hsl(0, 0%, 50%);
$light80: hsl(0, 0%, 55%);
$light70: hsl(0, 0%, 60%);
$light60: hsl(0, 0%, 65%);
$light50: hsl(0, 0%, 70%);
$light40: hsl(0, 0%, 75%);
$light30: hsl(0, 0%, 80%);
$light20: hsl(0, 0%, 85%);
$light15: hsl(0, 0%, 88%);
$light10: hsl(0, 0%, 90%);
$light5: hsl(0, 0%, 95%);

// red
$red100: hsl(354, 84%, 5%);
$red90: hsl(354, 82%, 15%);
$red80: hsl(354, 83%, 25%);
$red70: hsl(354, 82%, 35%);
$red60: hsl(354, 82%, 45%);
$red50: hsl(354, 82%, 55%);
// official color down
$red40: hsl(354, 82%, 61%);
$red30: hsl(354, 82%, 65%);
$red20: hsl(354, 83%, 75%);
$red10: hsl(353, 82%, 85%);
$red5: hsl(354, 84%, 95%);

// green
$green100: hsl(130, 100%, 5%);
$green90: hsl(130, 100%, 10%);
$green80: hsl(130, 100%, 20%);
// official color down
$green70: hsl(130, 100%, 31%);
$green60: hsl(130, 100%, 40%);
$green50: hsl(130, 100%, 50%);
$green40: hsl(130, 100%, 60%);
$green30: hsl(130, 100%, 70%);
$green20: hsl(130, 100%, 80%);
$green10: hsl(130, 100%, 90%);
$green5: hsl(130, 100%, 95%);

// blue
$blue100: hsl(231, 76%, 5%);
$blue90: hsl(229, 76%, 10%);
$blue80: hsl(230, 76%, 20%);
$blue70: hsl(230, 76%, 30%);
$blue60: hsl(230, 76%, 40%);
$blue45: hsl(215, 79%, 46%);
// official links
$blue50: hsl(230, 76%, 50%);
// official color down
$blue40: var(--official-color);
$blue30: hsl(230, 76%, 65%);
$blue20: hsl(230, 76%, 75%);
$blue10: hsl(231, 76%, 85%);
$blue5: hsl(231, 76%, 95%);

// gap
$gapBase: 8px;
$gapDefault: 16px;

// line height
$defaultRatio: 1.3;
$goldenRatio: 1.618;

// font base
$fontBase: 16px;

// font family
$fontOpenSans: 'Open Sans';

// font weight
$fontWeight100: 100;
$fontWeight200: 200;
$fontWeight300: 300;
$fontWeight400: 400;
$fontWeight500: 500;
$fontWeight600: 600;
$fontWeight700: 700;
$fontWeight800: 800;
$fontWeight900: 900;

// header
$headerHeight: #{$gapBase * 8};
$sectionHeaderHeight: #{$gapBase * 11};
