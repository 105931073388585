.alert {
  border: 1px solid #d9d9d9;
  background-color: $white;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 6px;

  & > p {
    margin-bottom: 0;
  }

  &-primary {
    color: #004085;
    border-color: #b8daff;
    background-color: #cce5ff;
  }

  &-secondary {
    color: #155724;
    border-color: #e2e3e5;
    background-color: #d6d8db;
  }

  &-success {
    border-color: #c3e6cb;
    background-color: #d4edda;
  }

  &-danger {
    color: #721c24;
    border-color: #f5c6cb;
    background-color: #f8d7da;
  }

  &-warning {
    color: #856404;
    border-color: #ffeeba;
    background-color: #fff3cd;
  }

  &-info {
    color: #0c5460;
    border-color: #bee5eb;
    background-color: #d1ecf1;
  }

  &-light {
    color: #818182;
    border-color: #fdfdfe;
    background-color: #fefefe;
  }

  &-dark {
    color: #1b1e21;
    border-color: #c6c8ca;
    background-color: #d6d8d9;
  }
}