.singlepage-row {
  display: flex;
  width: 100%;
}

.singlepage-row label {
  width: 150px;
  min-width: 150px;
}

.singlepage-row .tox-tinymce {
  width: 100%;
}

.singlepage-row .ant-form-item {
  width: 100%;
}

.singlepage-row div div div div div input {
  border-radius: 10px;
}

.singlepage-title {
  display: flex;
  justify-content: center;
  font-size: 24px;
}
