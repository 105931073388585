.section__email-verification {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: $light15;
    display: grid;
    justify-items: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    & > .card-wrapper {
        max-width: 800px;
    
        & .title {
            margin-bottom: 20px;
            text-align: center;
        
            & h1 {
                margin-top: 10px;
                line-height: 1.618;
            }
        }
    }
  }