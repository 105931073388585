.text {
  &-left {
    text-align: center;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }
}


// PAGE TITLE 
.page-title {
  color: $blue40;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  margin-bottom: 30px;
}

.p {
  &-0 {
    padding: 0 !important;
  }
}

.hr {
  &-x {
    border: 0;
    opacity: 0.7 !important;
    margin: 30px 0 20px 0 !important;
  }
}

.text-capitalize {
  text-transform: capitalize;
}