.section__categories {

  & .section__header {
    display: flex;
    justify-content: space-between;

    & .left {

    }

    & .right {

    }
  }

  & .section__content {
    & > .wrapper {
      display: grid;
      min-height: calc(100vh - #{$sectionHeaderHeight} - 174px);

      @media (min-width: 1200px) {
        min-height: calc(100vh - #{$sectionHeaderHeight} - 130px);
      }

      & .sorting {
        & .expand-trigger,
        & .contract-trigger {
          overflow: hidden !important;
        }

        & .title {
          margin-bottom: 0;
          display: flex;
          align-items: center;
          column-gap: 20px;

          & .image {
            display: block;
            width: 60px;
            height: 60px;
            border-radius: 6px;
          }

          & .text {
            display: block;
          }
        }
      }
    }
  }


  & .ant-form {
    & .form-group-attributes {
      display: grid;
      grid-template-columns: 1fr 50px;
      column-gap: 16px;
    }
    & .featured-cat-wrap {
      display: flex;
      gap: 10px;
      & > * {
        cursor: pointer;
      }
      & label b {
        bottom: 3px;
      }
    }
  }
}