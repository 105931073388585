*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  -webkit-overflow-scrolling: touch;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
	margin:0;
	padding:0;
	-webkit-tap-highlight-color: transparent;
	-webkit-tap-highlight-color: rgba(255,255,255,0);
}

// user selection 
::-moz-selection {
  background-color: $black;
  color: white;
}

::selection {
  background-color: $black;
  color: white;
}

// input element selection
::placeholder {
  color: #B6B6B6 !important;
  opacity: 1;
}

::-moz-placeholder {
  opacity: 1;
  color: #B6B6B6 !important;
}

::-ms-input-placeholder {
  color: #B6B6B6 !important;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: #B6B6B6 !important;
}

// scrollbar styling
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: $light5;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: $light5;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: $light70;
}