.section__roles {
  position: relative;

  & .table-grid-layout {
    & .group {
      @media (min-width: 2136px) {
        grid-template-columns: repeat(7, 1fr);
      }
    }
  }
}