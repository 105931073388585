.section__gallery {
  & .min-h-table-content {
    min-height: calc(100% - 108px);
  }

  & .section__header {
    grid-template-columns: 1fr auto;

    & .actions {
      justify-content: flex-end;

      & .upload-btn {

        & .ant-upload-list {
          display: none !important;
        }
      }
    }
  }

  & .ant-upload {
    border: 0 !important;
  }


  & .upload-area-btn {
    & .ant-upload {
      padding: 0 !important;
    }
  }

  // Gallery Upload
  & .upload-area {
    display: grid;
    background-color: $white;
    margin: 20px 20px 0;
    padding: 40px 20px;
    width: calc(100% - 40px);
    border: 2px dashed $blue40 !important;
    border-radius: 10px;

    & .ant-upload {
      padding: 0 !important;

      & > span {
        padding: 0 !important;
      }

      & .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        & .btn-close {
          position: absolute;
          right: 0;
          top: -10px;
          width: 50px;
          height: 50px;
          background-color: $white;
          z-index: 10;
        }

        & .block {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          grid-gap: 20px;

          & h3 {
            margin: 0;
            font-weight: 500;
            letter-spacing: 0.05em;
            font-size: 20px;
            color: $dark20;
          }

          & p {
            margin: 0;
            color: $light60;
            font-size: 17px;
            letter-spacing: 0.05em;
          }
        }
      }
    }
  }
}


.upload-area {
  display: grid;
  background-color: $white;
  margin: 20px 20px 0;
  padding: 40px 20px;
  width: calc(100% - 40px) !important;
  border: 2px dashed $blue40 !important;
  border-radius: 10px !important;

  & .ant-upload {
    padding: 0 !important;

    &>span {
      padding: 0 !important;
    }

    & .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      & .btn-close {
        position: absolute;
        right: 0;
        top: -10px;
        width: 50px;
        height: 50px;
        background-color: $white;
        z-index: 10;
      }

      & .block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 20px;

        & h3 {
          margin: 0;
          font-weight: 500;
          letter-spacing: 0.05em;
          font-size: 20px;
          color: $dark20;
        }

        & p {
          margin: 0;
          color: $light60;
          font-size: 17px;
          letter-spacing: 0.05em;
        }

        & button {
          max-width: inherit !important;
        }
      }
    }
  }
}

.gallery-view {
  & .tabs__panel {
    &-header {
      & ul {
        & li {
          color: $dark20;

          &.isActive {
            color: $blue40;
          }

          &:hover {
            color: $blue40;
          }
        }
      }
    }
  }
}

.gallery-images-header {
  width: calc(100% - 40px);
  height: 60px;
  margin-inline: 20px; 
  display: grid;

  & .search {
    position: relative;
    width: 100%;
    max-width: 850px;

    & .search-form {
      display: grid;
      grid-template-columns: 1fr auto;
      height: 50px;

      & .form-group {
        position: relative;
        display: grid;
        grid-template-columns: 50px 1fr 50px;

        & .input {
          border: 0;
          width: 100%;
          height: 100%;
          padding-left: 10px;
          padding-right: 10px;
          border-top: 1px solid #d9d9d9;
          border-bottom: 1px solid #d9d9d9;
          transition: border-color 0.3s ease;
          color: hsl(0, 0%, 15%);
        }

        & .icon {
          width: 50px;
          height: 50px;
          z-index: 10;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: hsl(0, 0%, 100%);
          transition: border-color 0.3s ease;
        }

        & .icon-close {
          border-top: 1px solid #d9d9d9;
          border-bottom: 1px solid #d9d9d9;
          border-right: 1px solid transparent;
          cursor: pointer;

          & img {
            opacity: 0;
            padding: 5px;
            width: 24px !important;
            background-color: #d9d9d9;
            border-radius: 50%;
            transition: opacity 0.3s ease;
          }

          &.isActive {
            & img {
              opacity: 1;
            }
          }
        }

        & .icon-search {
          border-top: 1px solid #d9d9d9;
          border-bottom: 1px solid #d9d9d9;
          border-left: 1px solid #d9d9d9;
          border-radius: 5px 0 0 5px;
        }
      }

      & .form-submit {
        background-color: hsl(0, 0%, 100%);
        color: var(--official-color);
        width: 100px;
        border: 1px solid #d9d9d9;
        border-radius: 0 5px 5px 0;
        font-weight: 500;

        cursor: pointer;
      }
    }
  }
}

.gallery-images-select {
  & .form-group {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    margin-top: 10px;

    & .label {
      display: inline-flex;
      font-size: 14px;
      margin-bottom: 0 !important;
      user-select: none;

      & b {
        font-weight: 500;
      }
    } 
  }
}

.gallery-images-actions {
  padding-inline: 20px;
  
  & .actions {
    padding-top: 20px;
    border-top: 1px solid #d9d9d9;
  }
}

.gallery-images,
.gallery-images-select {
  position: relative;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;

  & .row {
    display: grid;
    grid-gap: 30px;

    @media (min-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 768px) {}

    @media (min-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1400px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: 1600px) {
      grid-template-columns: repeat(6, 1fr);
    }

    & .card {
      position: relative;
      width: 100%;
      height: 200px;
      border: 1px solid #d9d9d9;
      background-color: $white;
      border-radius: 10px;
      padding: 10px;
      overflow: hidden;

      & .img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }


      & .figcaption {
        position: absolute;
        inset: 0;
        background-color: rgba(0, 0, 0, .2);
        z-index: 10;
        height: 100%;
        min-height: inherit;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease all;
        overflow: hidden;
        border-radius: 10px;
        display: grid;
        align-items: center;

        & .actions {
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 10px;

          & .btn {
            width: 36px;
            height: 36px;
            background-color: #fff;
            border-radius: 4px;

            & .icon {
              width: 20px;
              height: 20px;
              display: block;
              margin: auto;

              & img {
                width: 100%;
                display: block;
              }
            }
          }
        }
      }

      &:hover {
        & .figcaption {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

 // Gallery View Modal
.gallery-view {
  border-radius: 20px 20px 0 !important;

  & .header {
    border-bottom: 1px solid $light15;

    & h4 {
      margin-bottom: 0;
    }
  }

  & .content {
    display: grid;
    height: inherit;
    justify-content: inherit;
    align-items: inherit;
    flex-direction: inherit;
    height: calc(100% - 64px);
    overflow-y: auto;
    padding: 0;

    & .view-layout {
      display: grid;
      grid-template-columns: 1fr 600px;
      flex-direction: inherit;
      align-items: inherit;
      justify-content: inherit;
      overflow: hidden;

      & .content-image {
        padding: 30px;
        overflow-y: auto;
        background-color: $white;

        & img {
          display: block;
          width: auto;
          max-width: 100%;
          margin: auto
        }
      }

      & .content-info {
        color: $dark70;
        background-color: $light15;
        padding: 30px;

        & .information {
          &:first-child {
            border-bottom: 1px solid #ccc;
            padding-bottom: 30px;
            margin-bottom: 30px;
          }

          &:last-child {
            border-top: 1px solid #ccc;
            padding-bottom: 30px;
            margin-bottom: 30px;

            & .actions {
              display: flex;
              justify-content: flex-end;
            }

            & .btn-danger {
              font-weight: 500;
              width: auto;
              max-width: initial;
              padding: 15px;
              color: $red40;
            }
          }

          & p {
            &:is(:last-child) {
              margin-bottom: 0;
            }
          }
        }

        & .form-update-images {
          position: relative;
          padding: 30px;
          background-color: $white;
          border-radius: 10px;

          border-bottom: 1px solid #ccc;
          margin-bottom: 30px;
        }

        & .form {
          & .label {
            display: block;
            margin-bottom: 8px;
          }

          & .input {
            width: 100%;
            height: 50px;
            padding: 0 15px;
            border-radius: 6px;
          }

          & .actions {
            display: flex;
            grid-gap: 15px;
            justify-content: flex-end;

            & .btn {
              min-width: 150px;
            }
          }
        }
      }
    }
  }
}