@use "sass:math";

// all side of margins 
.m {
  &-0 {
    margin: 0 !important;
  }

  &-1 {
    margin: #{$gapBase * 1};
  }

  &-2 {
    margin: #{$gapBase * 2};
  }

  &-3 {
    margin: #{$gapBase * 3};
  }

  &-4 {
    margin: #{$gapBase * 4};
  }

  &-5 {
    margin: #{$gapBase * 5};
  }

  &-6 {
    margin: #{$gapBase * 6};
  }
}

// top side of margins 
.mt {
  &-0 {
    margin-top: 0 !important;
  }

  &-1 {
    margin-top: #{$gapBase * 1};
  }

  &-2 {
    margin-top: #{$gapBase * 2};
  }

  &-3 {
    margin-top: #{$gapBase * 3};
  }

  &-4 {
    margin-top: #{$gapBase * 4};
  }

  &-5 {
    margin-top: #{$gapBase * 5};
  }

  &-6 {
    margin-top: #{$gapBase * 6};
  }
}

// right side of margins 
.mr {
  &-0 {
    margin-right: 0 !important;
  }

  &-1 {
    margin-right: #{$gapBase * 1};
  }

  &-2 {
    margin-right: #{$gapBase * 2};
  }

  &-3 {
    margin-right: #{$gapBase * 3};
  }

  &-4 {
    margin-right: #{$gapBase * 4};
  }

  &-5 {
    margin-right: #{$gapBase * 5};
  }

  &-6 {
    margin-right: #{$gapBase * 6};
  }
}

// bottom side of margins 
.mb {
  &-0 {
    margin-bottom: 0 !important;
  }

  &-1 {
    margin-bottom: #{$gapBase * 1};
  }

  &-2 {
    margin-bottom: #{$gapBase * 2};
  }

  &-3 {
    margin-bottom: #{$gapBase * 3};
  }

  &-4 {
    margin-bottom: #{$gapBase * 4};
  }

  &-5 {
    margin-bottom: #{$gapBase * 5};
  }

  &-6 {
    margin-bottom: #{$gapBase * 6};
  }
}

// left side of margins 
.ml {
  &-0 {
    margin-left: 0 !important;
  }

  &-1 {
    margin-left: #{$gapBase * 1};
  }

  &-2 {
    margin-left: #{$gapBase * 2};
  }

  &-3 {
    margin-left: #{$gapBase * 3};
  }

  &-4 {
    margin-left: #{$gapBase * 4};
  }

  &-5 {
    margin-left: #{$gapBase * 5};
  }

  &-6 {
    margin-left: #{$gapBase * 6};
  }
}