.tab-panels {
  position: relative;
  // overflow: hidden;
  border-radius: 10px;

  & .panels-header {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }

  & .panels-body {
    border-radius: 10px;

    & .wrapper {
      background-color: transparent !important;
    }
  }

  & .panels {
    position: relative;

    &-header {
      & .navbar {
        background-color: white;
        border-bottom: 1px solid #d9d9d9;
        padding: 0;
        height: 60px;

        & ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          display: grid;
          justify-content: space-between;
          grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
          grid-gap: 15px;

          & li {
            display: grid;

          }

          & button {
            margin: 0;
            border: 0;
            outline: 0;
            padding: 15px;
            font-size: 16px;
            background-color: transparent;
            cursor: pointer;
            width: 100%;
            box-shadow: none;
            display: flex;
            grid-gap: 10px;
            align-items: center;
            justify-content: center;
            border-bottom: 3px solid transparent;

            & .icon {
              width: 20px;
              height: 20px;
              display: block;

              & img {
                width: 100%;
                display: block;
              }
            }

            & .text {
              font-size: 18px;
            }

            &.isActive {
              border-color: $blue40;
              & .text {
                color: $blue40;
              }
            }


          }
        }
      }
    }

    &-body {
      display: grid;

      & .wrapper {
        background-color: white;
        padding: 15px;
        // min-height: 300px;

        & .item {
          display: none;

          &.isActive {
            display: block;
          }
        }
      }
    }
  }
}