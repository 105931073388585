@media (max-width: 1199.98px) {
  .gl__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background-color: $blue40;
    display: grid;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 991;

    & .menu-btn {
      position: relative;
      height: 48px;
      display: flex;
      align-items: center;

      & .icon {
        display: grid;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 100%;

        & img {
          display: block;
          width: 24px;
        }
      }


      & .text {
        color: $white;
        font-weight: 700;
      }
    }
  }
}


@media (min-width: 1200px) {
  .gl__header {
    display: none;
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
  }
}