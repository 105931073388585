.section__not-found-error {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 124px);
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);  

  @media (min-width: 1200px) {
    min-height: calc(100vh - 80px);
  }

  // Actions
  & .actions {
    display: grid;
    justify-content: flex-end;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  // Form
  .form {
    color: $dark40;
    
    & .form-row {
      position: relative;
      display: flex;
      grid-gap: 15px;
      align-items: center;
      padding-bottom: 15px;
    }

    & .form-group {
      display: flex ;
      grid-gap: 15px;
      margin-bottom: 0;

      & .select,
      & .input {
        color: $dark40;
        width: 220px;
        height: 50px;
        border-color: #d9d9d9;
        border-radius: 4px;
        padding-inline: 15px;
        background-color: white;
      }
    }    

    & .hidden-form {
      width: 100%;
      height: 0;
      overflow: hidden;
      transition: height .3s;
      background-color: #d9d9d9;
      border-radius: 6px;
      margin-bottom: 20px;

      & .block {
        padding: 15px;
      }
    }

    & .actions {
      display: flex;
      grid-gap: 15px;
      border-bottom: 0;
      justify-content: flex-start;
    }
  }
}

