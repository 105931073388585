.rich-text-editor {
  & table {
    & tr {
      & td {
        padding: 10px;
        border-color: #d9d9d9;
      }
    }
  }

}

.tox-tinymce {
  border: 0 !important;
}