/* default and desktop table */
.table {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  color: #333;
}

.table .thead {
  position: sticky;
  top: 0;
  width: 100%;
  height: 54px;
  background-color: #fff;
  z-index: 10;
}

.table .thead tr th,
.table .tbody tr td {
  padding: 12px;
  text-align: left;
  border: 1px solid #d9d9d9;
  border-top: 0;
}

.table .thead tr th,
.table .tbody tr td {
  min-width: 50px;
}

.table .thead tr th:first-of-type,
.table .tbody tr td:first-of-type {
  min-width: 1px;
  width: 60px !important;
  text-align: center;
  border-left: 0;

  & input[type=checkbox] {
    margin: auto;
  }
}

.table .thead tr th:nth-child(2),
.table .tbody tr td:nth-child(2) {
  width: 260px;
}


.table .thead tr th:last-of-type,
.table .tbody tr td:last-of-type {
  min-width: 1px;
  width: 120px;
  text-align: center;
  border-left: 0;
}

.table .thead tr th:last-of-type,
.table .tbody tr td:last-of-type {
  border-right: 0;
}

.table .tbody tr:nth-child(odd) {
  background-color: rgb(255, 255, 255);
}

.table .tbody tr:nth-child(even) {
  background-color: rgb(242,242,242);
}

.table th,
.table td {
  padding: 0;
}

// FORM ELEMENT 
.section__header {
  & select {
    height: 50px;
    padding: 0 15px;
    border-radius: 6px;
    background-color: #fff;
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    border: 1px solid #d9d9d9;
    min-width: 100px;
    padding: 0 10px;
    outline: 0;
    line-height: 1;
    border-radius: 5px;
    background-repeat: no-repeat;
    // background-image: url('../../../public/assets/icons/select_arrow.png');
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAqElEQVR42r1QSwqEMAwNiIsiGEFEKlhFR7AL24UH8Ag9gkfOUXKEDOM4Mh9n63uLJI8X8gG4AnOY8VTHOQCAW7w4mn4sEzry4hawbMWKpfHDMqKlTWcY1ps82FN3WDrs6akOKwC0wbARI4bqzVKjoa3mNuwdldesRYvm0pdHXvm3mYXPOZdccn7Fwn9tnTUppbKTsubk7gQVKVGiKME/L4sxoohihGtxB2vkQp9V9ml2AAAAAElFTkSuQmCC');
    background-position-y: center;
    background-position-x: 90%;
    background-size: 12px;
    appearance: none;
    transition: all 0.3s;

    &:hover {
      border-color: #40a9ff;
    }
  }
}

// PAGINATION
.pagination {
  position: relative;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
  border-bottom: 1px solid #d9d9d9;
  background-color: $white;

  .bullet {
    display: flex;
    column-gap: 10px;

    & .btn {
      border-radius: 4px;
      &-pagination {
        min-width: 30px;
        height: 30px;
        padding: 0 4px;
        background-color: $light15;
        border: 2px solid $light15;
        transition: all 0.4s 0.5s linear;
        color: $black;

        &.is-active {
          color: $white;
          border-color: $blue40;
          background-color: $blue40;
        }
      }
    }
  }

  .btn {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    color: #333;
    height: 30px;
    color: $blue40;
    border-radius: 6px;
    font-family: 'Open Sans';
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    border-radius: 4px;

    // prev, next, first and last button
    &-prev {
      transform: rotate(90deg);
    }

    &-next {
      transform: rotate(-90deg);
    }

    &-first {
      transform: rotate(180deg);
    }

    &-last {
      transform: rotate(0);
    }

    &-prev, 
    &-next,
    &-first,
    &-last {
      width: 30px;
      height: 30px;
      
      & > img {
        display: block;
        margin: auto;
        width: 16px;
        opacity: 0.75;
      }
    }

    &-first {
      opacity: 0.7;
      visibility: hidden;
      transition: all 0.4s 0.5s linear;

      &.show {
        opacity: 0.7;
        visibility: visible;
      }
    }

    &-last {
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s 0.5s linear;

      &.show {
        opacity: 0.7;
        visibility: visible;
      }
    }

    &[disabled] {
      border: 0;
      opacity: 0.5;
    }
  }
}

.table .active,
.table .inactive {
  position: relative;
  height: 100%;
  padding: 7px 14px 7px 7px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
}

.table .active::before,
.table .inactive::before {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  content: '';
  clear: both;
  display: block;
  margin-right: 10px;
}

.table .active {
  color: hsla(177, 100%, 33%, 1);
  background-color: hsla(176, 46%, 93%, 1);
}

.table .active::before {
  background-color: hsla(177, 100%, 33%, 1);
}

.table .inactive {
  color: hsla(354, 82%, 61%, 1);
  background-color: hsla(354, 82%, 61%, 0.1);
}

.table .inactive::before {
  background-color: hsla(354, 82%, 61%, 1);
}

.table {
  & .tbody {
    & .permissions {
      padding: 0;

      & .table-grid-layout {
        padding: 12px;
      }
    }
  } 
}


// TABLE ACTIONS 
.actions {
  display: grid;

  & .btn-actions {
    width: 100%;
    display: flex;
    justify-items: flex-start;
    align-items: center;
    text-align: left;
    column-gap: 9px;
    min-height: 30px;
    
    &:not(:last-child) {
      padding-bottom: 10px;
      border-bottom: 1px solid #d9d9d9;
    }

    &:not(:first-child) {
      padding-top: 10px;
    }    

    & .icon {
      width: 18px;
      height: 18px;

      & img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    & .text {
      color: hsla(0, 0%, 39%, 1);
    }
  }

  & .actions-group {
    display: grid;
    row-gap: 10px;
    border-bottom: 1px solid #d9d9d9;

    & .btn-actions {
      border-bottom: 0;
      padding: 0;

      &:last-child {
        margin-bottom: 10px;
        padding-top: 0;
      }
    }
  }

  & .actions-group {
    & + .btn-actions {
      padding-top: 0;
      margin-top: 10px;
    }
  }

  & .hr {
    border-color: #f5f5f5;
    border-bottom: '1px'
  }
}

.form {
  .hr {
    margin: 30px 0;
    border-bottom: 1px solid #d9d9d9;
    opacity: 0.3;
  }
}

.bg-test {
  background-color: aquamarine !important;
}

.table-grid-layout {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
}

@media (min-width: 992px) {
  .table-grid-layout {
    grid-template-columns: 100px 1fr;
  }
}

.table-grid-layout .first {
  width: 100%;
}

.table-grid-layout .group {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 768px) {
  .table-grid-layout .group {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1400px) {
  .table-grid-layout .group {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1600px) {
  .table-grid-layout .group {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1800px) {
  .table-grid-layout .group {
    grid-template-columns: repeat(6, 1fr);
  }
}

// TABLE MESSAGE 
.content-message {
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.truncate {
  max-width: 550px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}