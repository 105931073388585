// ANT DESIGN POPOVER 
.ant-popover-inner {
  min-width: 150px;
  border-radius: 8px !important;
  & .th-actions button {
    border-radius: 4px;
    width: 100%;
    transition: all .2s;
  }
  & .th-actions button:hover {
    background-color: rgb(247, 247, 247)
  }
  & .th-actions label.text {
    cursor: pointer;
  }  
}

.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
}

.thead-actions {
  & .icon {
    transition: transform 0.3s ease;
  }
  &.ant-popover-open {
    & .icon {
      transform: rotate(180deg);
    }  
  }
}

.tbody-actions {
  & .icon {
    transition: transform 0.3s ease;
    display: grid;
  }

  &.ant-popover-open {
    & .icon {
      transform: rotate(90deg);
    }  
  }
}

// NOTIFICATION
.ant-notification-notice {
  border-radius: 6px;
}

// ANT DESIGN FORMS AND INPUT FIELDS
.ant-form {
  & .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: $blue40;
  }

  & .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $blue40;
  }

  & .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $blue40 !important;
    box-shadow: 0 0 0 2px rgba(0, 0, 200, 0.2) !important;
    box-shadow: none !important;
  }

  & .ant-input {
    &:hover, 
    &:focus {
      border-color: $blue40 !important;
    }

    &:focus,
    &.ant-input-focused {
      box-shadow: none;
    }
  }

  & .ant-input-affix-wrapper:focus, 
  & .ant-input-affix-wrapper-focused {
    border-color: $blue40 !important;
      box-shadow: none !important;
  }

  & .ant-select-selector {
    &:hover, 
    &:focus {
      border-color: $blue40 !important;
    }
    &:focus,
    &.ant-input-focused {
      box-shadow: none;
    }
  }

  & .ant-input-affix-wrapper {
    padding: 0;
    border-radius: 6px;
  }

  & .ant-input-suffix {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
  }

  & .ant-select-selector {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  & .ant-select-selection-item,
  & .ant-select-selector {
    line-height: 50px !important;
    border-radius: 6px !important;
  }

  & .ant-select,
  & .ant-select-selector,
  & .ant-form-item-control-input-content {
    min-height: 50px !important;
  }

  & .ant-select-arrow {
    right: 15px;
    color: $blue40;
    font-size: 16px;
    font-weight: 600;
  }

  & .ant-form-item-explain-error {
    line-height: 20px;
  }
}

.date-picker {
  & .ant-picker-range {
    height: 50px;
    border-radius: 6px;

    &:hover,
    &:focus {
      border-color: $blue40;
      box-shadow: none !important;
    }

    & .ant-picker-active-bar {
      background-color: $blue40;
    }
  }

  & .ant-picker-focused {
    border-color: $blue40 !important;
    box-shadow: none !important;
  }
}