.aboutus-row {
  display: flex;
  width: 100%;
}

.aboutus-row label {
  width: 150px;
  min-width: 150px;
}

.aboutus-row .tox-tinymce {
  width: 100%;
}

.aboutus-row .ant-form-item {
  width: 100%;
}

.aboutus-row div div div div div input {
  border-radius: 10px;
}

.aboutus-title {
  display: flex;
  justify-content: center;
  font-size: 24px;
}

.aboutus-step-row {
  display: flex;
}

.aboutus-step-row label {
  width: 150px;
  min-width: 150px;
}

.aboutus-step-row div div div div div input {
  border-radius: 10px;
}

.aboutus-step-row div div div div div textarea {
  border-radius: 10px;
}
