.dropdown {
  position: relative;
  color: #fff;
  list-style-type: none;
  margin-bottom: 0;
  margin-bottom: #{$gapBase * 3};
  // border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;

  &.isActive {
    & .dropdown-toggle {
      & .icon-arrow {
        transform: rotate(180deg);
      }
    }
  }

  // TOGGLE BUTTON 
  &-toggle {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    width: 100%;
    height: 50px;
    text-align: left;
    display: grid;
    grid-template-columns: 20px 1fr 20px;
    box-shadow: none;
    align-items: center;
    column-gap: 12px;
    cursor: pointer;


    // ICON of TOGGLE BUTTON
    & > .icon {
      width: 20px;
      height: 20px;
      & > span {
        & > svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    & > .icon-arrow {
      width: 20px;
      height: 20px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 16px;
      transition: all 0.3s;
    }

    // TEXT of TOGGLE BUTTON
    & > .text {

    }


    // HOVER of TOGGLE BUTTON 
    &:hover {
      background-color: transparent;
      color: $white;
    }
  }

  // DROPDOWN LIST ITEM
  & > li {
    display: grid;
    row-gap: $gapBase;
  }

  
  // COLLAPSE SUBMENU
  &-items {
    list-style-type: none;
    overflow: hidden;
    transition: all 0.3s;

    & li {
      & > a {
        display: flex;
        align-items: center;
        padding: 0 15px 0 30px;
        height: 36px;
        color: $white;
        border-radius: 6px;

        &.active {
          background-color: rgba($color: $white, $alpha: 0.3);
        }
      }
    }
  }
}
