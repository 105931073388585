// display
.d {

  // display
  &-grid {
    display: grid;
  }

  &-flex {
    display: flex;
  }

  &-block {
    display: block;
  }

  // inline display
  &-inline-grid {
    display: inline-grid;
  }

  &-inline-flex {
    display: flex;
  }

  &-inline-block {
    display: block;
  }

  &-none {
    display: none;
  }
}

// content 
.place-items-center {
  place-items: center;
}

.place-content-center {
  place-content: center;
}

.justify {
  &-between {
    justify-content: space-between;
  }

  &-around {
    justify-content: space-around;
  }

  &-evenly {
    justify-content: space-evenly;
  }

  &-start {
    justify-content: flex-start;
  }

  &-end {
    justify-content: flex-end;
  }
}

// items 
.items {
  &-center {
    justify-content: center;
  }

  &-start {
    justify-content: start;
  }

  &-end {
    justify-content: end;
  }
}

.align {
  &-center {
    align-items: center;
  }

  &-start {
    align-items: flex-start;
  }

  &-end {
    align-items: flex-end;
  }
}

// flex direction
.direction-column {
  flex-direction: column;
}

.direction-column {
  flex-direction: column;
}

// width 
.full-width {
  width: 100%;
}

.full-max-width {
  max-width: 100%;
}

// height
.full-height {
  height: 100%;
}

.full-min-height {
  min-height: 100%;
}

.min-height-full-vh {
  min-height: 100vh;
}

// container 
.container {
  width: 100%;
  max-width: 100%;
  padding-right: #{$gapBase * 2};
  padding-left: #{$gapBase * 2};
  margin-right: auto;
  margin-left: auto;
}

// container fluid
.container-fluid {
  width: 100%;
  max-width: 100%;
  padding-right: #{$gapBase * 2};
  padding-left: #{$gapBase * 2};
  margin-right: auto;
  margin-left: auto;
}

// grid column 
.col-1 {
  grid-template-columns: repeat(1, auto);
}

.col-2 {
  grid-template-columns: repeat(2, auto);
}

@media (min-width: 768px) {
  .col-2-md {
    grid-template-columns: repeat(2, auto);
  }
}

// column gap
.gap-x-1 {
  column-gap: #{$gapBase * 1};
}

.gap-x-2 {
  column-gap: #{$gapBase * 2};
}

.gap-x-3 {
  column-gap: #{$gapBase * 3};
}

.gap-x-4 {
  column-gap: #{$gapBase * 4};
}

.gap-x-5 {
  column-gap: #{$gapBase * 5};
}

.gap-x-6 {
  column-gap: #{$gapBase * 6};
}

.gap-x-7 {
  column-gap: #{$gapBase * 7};
}

.gap-x-8 {
  column-gap: #{$gapBase * 8};
}

.gap-x-9 {
  column-gap: #{$gapBase * 9};
}

.gap-x-10 {
  column-gap: #{$gapBase * 10};
}

// colum row
.gap-y-1 {
  row-gap: #{$gapBase * 1};
}

.gap-y-2 {
  row-gap: #{$gapBase * 2};
}

.gap-y-3 {
  row-gap: #{$gapBase * 3};
}

.gap-y-4 {
  row-gap: #{$gapBase * 4};
}

.gap-y-5 {
  row-gap: #{$gapBase * 5};
}

.gap-y-6 {
  row-gap: #{$gapBase * 6};
}

.gap-y-7 {
  row-gap: #{$gapBase * 7};
}

.gap-y-8 {
  row-gap: #{$gapBase * 8};
}

.gap-y-9 {
  row-gap: #{$gapBase * 9};
}

.gap-y-10 {
  row-gap: #{$gapBase * 10};
}

// vertical alignment
.y {
  &-top {
    vertical-align: top;
  }
  &-middle {
    vertical-align: middle;
  }
  &-bottom {
    vertical-align: bottom;
  }
  &-baseline {
    vertical-align: baseline !important;
  }
}

// POSITION 
.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.sticky {
  position: sticky;
}

.static {
  position: static !important;
}