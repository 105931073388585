.section__profile {
  position: relative;
  background-color: transparent;
  box-shadow: none;
  overflow: inherit;

  & .section__content {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    @media (min-width: 1600px) {   
      display: grid;
      grid-template-columns: 420px 1fr;
      column-gap: 30px;
      row-gap: 0;
      height: 100%;
    }

    @media (min-width: 1800px) {   
      grid-template-columns: 520px 1fr;
      height: 100%;
    }    

    & .wrapper {
      position: relative;
      height: 100%;

      &:nth-child(2) {
        margin-bottom: 40px;
      }
    }

    & .section__wrapper {

      @media (max-width: 1599.98px) {
        height: auto;
      }

      &--account {
        & .section__header {
        grid-template-columns: auto 50px;
        
          // User name text
          & h2 {
            margin-bottom: 0;
            font-size: 24px;
            font-weight: 500;
            font-family: 'Open Sans';
            color: $dark10;
          }
        
          // User update button
          & .btn {
            width: 50px;
            height: 50px;
          }
        }
      }

      &--activity {
        & .section__header {
          grid-template-columns: auto 1fr;
          justify-content: space-between;

          & h3 {
            margin-bottom: 0;
            color: $dark10;
          }

          & .search {
          }
        }
      }
    }

    & .content {
      // Profile information - content 
      &--account {
        padding: 30px;

        & .group {
          width: 100%;
          height: 50px;
          border: 1px solid #D9D9D9;
          border-radius: 6px;
          display: flex;
          align-items: center;
          padding: 0 15px;
          column-gap: 10px;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 30px;
          }
          

          & .label {
           color: $dark10; 
          }

          & .text {
            color: $dark10;
            font-weight: $fontWeight600;
          }
        }
      }
      // Activity log information - content 
      &--activity {
        background-color: transparent;
      }
    }

  }

  // FORMS
  & .form {

    &-update {
      & .group {
        width: 100%;
        height: 50px;
        border: 1px solid #D9D9D9;
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        column-gap: 10px;
        
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
  }

  // TABLE 
  & .table {
    & .thead {
      & tr {
        & th {
          &:last-child {
            text-align: left;
            width: 200px !important;
            max-width: 200px !important;
          }

          &:nth-child(1),
          &:nth-child(2) {
            width: 15% !important;
            max-width: 140px !important;
            text-align: left;
          }

          &:nth-child(3) {
            width: 100%;
          }
        }
      }
    }

    & .tbody {
      & tr {
        & td {
          &:nth-child(1),
          &:nth-child(2) {
            width: 15% !important;
            max-width: 140px !important;
            text-align: left;
          }

          &:nth-child(3) {
            width: 100%;
          }
        }
      }
    }    
  }
}