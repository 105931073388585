.section__activity-log {
  & .table {
    & .thead {
      & tr {
        & th {
          width: 20% !important;
          text-align: left;

          &:first-child {
            padding-left: 20px;
          }
        }
      }
    }

    & .tbody {
      & tr {
        & td {
          width: 20% !important;
          text-align: left;

          &:first-child {
            padding-left: 20px;
          }
        }
      }
    }    
  }

  & .section__header {
    grid-template-columns: repeat(2, auto) minmax(auto, 750px);
    justify-content: flex-start;
  }
}