// BREADCRUMBS
.breadcrumbs {
  position: relative;
  top: -8px;

  & ul {
    margin: 0;
    list-style-type: none;
    display: flex;
    grid-gap: 15px;
    margin-bottom: 14px;

    & li {
      &:first-child {
        & .link {
          color: $dark20;
          padding-left: 0;
        }
      }

      &:not(:last-child) {
        & .link {
          &::after {
            position: absolute;
            right: -10px;
            width: 10px;
            height: 20px;
            content: '';
            clear: both;
            background-image: url('data: image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAxMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNzUzOCAwLjU1NTUyQzEuOTY3MiAwLjU1NDc5MyAyLjE3ODA1IDAuNjAxODkxIDIuMzcwODYgMC42OTMzNTJDMi41NjM2NyAwLjc4NDgxNCAyLjczMzU0IDAuOTE4MzEzIDIuODY3OTggMS4wODQwNEw5Ljc2NzMzIDkuNjU0NjZDOS45Nzc0MyA5LjkxMDI1IDEwLjA5MjMgMTAuMjMwOSAxMC4wOTIzIDEwLjU2MTdDMTAuMDkyMyAxMC44OTI2IDkuOTc3NDMgMTEuMjEzMiA5Ljc2NzMzIDExLjQ2ODhMMi42MjUxNSAyMC4wMzk0QzIuMzgyNjkgMjAuMzMxMSAyLjAzNDI4IDIwLjUxNDYgMS42NTY1NiAyMC41NDk0QzEuMjc4ODQgMjAuNTg0MiAwLjkwMjc2NSAyMC40Njc2IDAuNjExMDU0IDIwLjIyNTFDMC4zMTkzNDQgMTkuOTgyNiAwLjEzNTg5OSAxOS42MzQyIDAuMTAxMDczIDE5LjI1NjVDMC4wNjYyNDc4IDE4Ljg3ODggMC4xODI4OTYgMTguNTAyNyAwLjQyNTM1OCAxOC4yMTFMNi44MTA0NyAxMC41NTQ2TDAuNjM5NjIyIDIuODk4MTVDMC40NjQ5NDggMi42ODg0OCAwLjM1Mzk5MiAyLjQzMzE2IDAuMzE5ODgxIDIuMTYyNEMwLjI4NTc3MSAxLjg5MTY1IDAuMzI5OTMzIDEuNjE2NzggMC40NDcxNDUgMS4zNzAzNEMwLjU2NDM1NyAxLjEyMzg5IDAuNzQ5NzEgMC45MTYxNzkgMC45ODEyNzUgMC43NzE3NzlDMS4yMTI4NCAwLjYyNzM3NyAxLjQ4MDkyIDAuNTUyMzMxIDEuNzUzOCAwLjU1NTUyWiIgZmlsbD0iIzYzNjM2MyIvPgo8L3N2Zz4K');
            background-size: 10px 20px;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }

      & .link {
        position: relative;
        padding: 0 15px;
        height: 36px;
        display: flex;
        align-items: center;
        color: $blue40;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 20px;
      }
    }
  }
}