.page-title {
    &.order-details {
      margin-bottom: 30px;
        & > a {
        display: inline-block;
        margin-right: 15px;
        background-color: transparent;
        border: none;
        color: #636363;
        transition: all .3s;
        &:hover {
            text-shadow: 0px 0px 60px #030303;
        }
    }
    & img {
        margin-right: 15px;
        height: 20px;
    }
    }
  }
  .section__order-details{
    color: #636363;
    font-size: 20px;
    & .section__header {
        padding-inline: 30px;
        grid-template-columns: 1fr auto auto;
        & .title-details {
            margin-inline: 10px;
            font-size: 18px;
            & .order-date-id {
                color: #4762E6;
                font-weight: 600;
            }
        }
    }
    & .table {
        font-size: 16px;

        & tr {

          & td {
            & .product-item {
              display: grid;
              grid-gap: 15px;
              align-items: center;
              grid-template-columns: 100px 1fr;

              & img {
                margin: 0;
                width: 100px;
                height: 100px;
                object-fit: cover;
              }
            }
          }
        }

        & tr th:first-child {
            text-align: left;
            padding-left: 35px;
        }
        & tr th:nth-child(2), tr td:nth-child(2) {
            text-align: center;
            width: 80px;
        }
        & tr td:first-child img {
            // margin-left: 20px;
            // margin-right: 15px;
        }
        & tr td:first-child span {
            display: inline-block;
            margin-right: 15px;
        }
        & .subtotal-row {
            font-size: 20px;
            font-weight: 600;
            & td {
                border-bottom-width: 0px;
            }
        }

    }
  }