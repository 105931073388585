.section__home {
  position: relative;
}


/* DELETE THIS CSS */
.section__home {
  & .show-roles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;

    .row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
    }

    & p {
      & span {

        &:last-child {
          font-weight: $fontWeight700;
        }

        &.red {
          color: green;
        }

        &:not(:first-child) {
          &:not(.red) {
            color: red;
          }
        }
      }
    }
  }
}