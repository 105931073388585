@use "sass:math";

// all side of paddings 
.p {
  &-0 {
    padding: 0 !important;
  }

  &-1 {
    padding: #{$gapBase * 1};
  }

  &-2 {
    padding: #{$gapBase * 2};
  }

  &-3 {
    padding: #{$gapBase * 3};
  }

  &-4 {
    padding: #{$gapBase * 4};
  }

  &-5 {
    padding: #{$gapBase * 5};
  }

  &-6 {
    padding: #{$gapBase * 6};
  }
}

// top side of paddings 
.pt {
  &-0 {
    padding-top: 0 !important;
  }

  &-1 {
    padding-top: #{$gapBase * 1};
  }

  &-2 {
    padding-top: #{$gapBase * 2};
  }

  &-3 {
    padding-top: #{$gapBase * 3};
  }

  &-4 {
    padding-top: #{$gapBase * 4};
  }

  &-5 {
    padding-top: #{$gapBase * 5};
  }

  &-6 {
    padding-top: #{$gapBase * 6};
  }
}

// right side of paddings 
.pr {
  &-0 {
    padding-right: 0 !important;
  }

  &-1 {
    padding-right: #{$gapBase * 1};
  }

  &-2 {
    padding-right: #{$gapBase * 2};
  }

  &-3 {
    padding-right: #{$gapBase * 3};
  }

  &-4 {
    padding-right: #{$gapBase * 4};
  }

  &-5 {
    padding-right: #{$gapBase * 5};
  }

  &-6 {
    padding-right: #{$gapBase * 6};
  }
}

// bottom side of paddings 
.pb {
  &-0 {
    padding-bottom: 0 !important;
  }

  &-1 {
    padding-bottom: #{$gapBase * 1};
  }

  &-2 {
    padding-bottom: #{$gapBase * 2};
  }

  &-3 {
    padding-bottom: #{$gapBase * 3};
  }

  &-4 {
    padding-bottom: #{$gapBase * 4};
  }

  &-5 {
    padding-bottom: #{$gapBase * 5};
  }

  &-6 {
    padding-bottom: #{$gapBase * 6};
  }
}

// left side of paddings 
.pl {
  &-0 {
    padding-left: 0 !important;
  }

  &-1 {
    padding-left: #{$gapBase * 1};
  }

  &-2 {
    padding-left: #{$gapBase * 2};
  }

  &-3 {
    padding-left: #{$gapBase * 3};
  }

  &-4 {
    padding-left: #{$gapBase * 4};
  }

  &-5 {
    padding-left: #{$gapBase * 5};
  }

  &-6 {
    padding-left: #{$gapBase * 6};
  }
}