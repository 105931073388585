.loader-button {
    position: absolute;
    left: -24px;
    top: 1px;
    width: 18px;
    height: 18px;
    border: 2px solid #DDD;
    border-bottom-color: #999;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 