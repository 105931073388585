.section__forgot-password {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: $light15;
  display: grid;
  justify-items: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;

  & .title {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    column-gap: 10px;

    & h1 {
      line-height: 1.618;
    }
  }
}